@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500&display=swap');

/* vars */
:root {
    --background-gradient-light: #525e7a84;
    --background-gradient-dark: #23283284;
    --background-hover: #0dff05;
    --background-body: #1a1f28;
    --text-dark: #ccc;
    --text-light: #eee;
    --bright-green: #0dff05;
    --bright-blue: #76b8f5;
    --dark-blue: #00aaff34;
    --dark-blue-hover: #00aaff4f;
    --bright-blue-text: #aabed6;
    --poppins: 'Poppins', sans-serif;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #1a1f28;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

a {
    text-decoration: none;
    color: #ffffff;
}
