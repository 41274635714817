.projectWrapper {
    display: flex;
    flex-direction: row;
}

.projectLeftWrapper {
    margin-bottom: 10px;
}

.projectDate {
    font-size: 0.9rem;
    margin-bottom: 0;
    font-weight: 700;
    color: var(--bright-blue-text);
}

.projectImageWrapper {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 10px;
    background: var(--bright-blue);
    overflow: hidden;
    border-radius: 5px;
}

.projectImage {
    width: 300px;
    height: 200px;
    border-radius: 5px;
    object-fit: cover;
    opacity: 0.7;
}

.projectImage:hover,
.projectImage:focus {
    opacity: 1;
    transition: all 0.1s ease-in-out;
}

.projectInfoWrapper {
    margin-top: 40px;
    margin-left: 4px;
}

.projectName {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 600;
    background: -webkit-linear-gradient(#ffffff, var(--bright-blue));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.projectDescription {
    color: var(--bright-blue-text);
    font-weight: 500;
    font-size: 15px;
}

.projectTool:hover {
    background-color: rgb(71, 90, 111);
}

.projectSourceCodeLinkIcon {
    margin-top: 10px;
    color: #ffffff;
}

.projectDemoLinkIcon {
    margin-left: 20px;
    color: #ffffff;
}

.projectSourceCodeLinkIcon:hover,
.projectDemoLinkIcon:hover {
    color: var(--bright-blue);
    transition: all 0.1s ease-in-out;
}

@media (max-width: 620px) {
    .projectWrapper {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .projectLeftWrapper {
        width: 100%;
    }
    .projectImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .projectImageWrapper {
        width: 100%;
        height: 100%;
    }
    .projectLinkWrapper {
        margin-bottom: 12px;
    }
}
